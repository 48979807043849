<template>
  <b-tabs
    lazy
    pills
    nav-class="mb-0"
    active-nav-item-class="bg-primary box-shadow-info"
  >
    <b-tab
        title="User Commissions"
        :title-link-class="[bgTabsNavs, 'px-3']"
    >
      <table-commissions-detail
          :role-type="1"
          :info="info"
          :tab-controller="tabController"
          :role-c-digital="roleInFinancial"
      />
    </b-tab>
    <b-tab lazy title="Commissions from department" :title-link-class="[bgTabsNavs, 'px-3']">
      <table-commissions-detail :info="info" :tab-controller="tabController" :role-c-digital="roleInFinancial" :role-type="2"/>
    </b-tab>
  </b-tabs>
</template>

<script>
import TableCommissionsDetail from "@/views/commons/components/commissions/modals/modal-details/TableCommissionsDetail.vue";
import NewCommissionsService from "@/views/commons/components/commissions/services/new-commissions.service";
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    tabController: {
      type: Number,
      default: 0,
    },
  },
  components: {
    TableCommissionsDetail,
  },
  data() {
    return {
      tabIndex: 1,
      roleInFinancial: 15,
      commissionService: {},
    };
  },
  computed: {
    getModuleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.commissionService = new NewCommissionsService();
    const data = await this.commissionService.getUserRoleByModuleId({
      module_id: 23,
      user_id: this.info.user_id,
    });
    if (data.length > 0) {
      this.roleInFinancial = data[0].role_id;
    }
  },
};
</script>

<style scoped></style>
