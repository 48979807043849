<template>
  <div>
    <b-tabs
        lazy
        pills
        nav-class="mb-0"
        active-nav-item-class="bg-primary box-shadow-info"
        class="mt-1"
    >
      <b-tab title="User Commissions"  :title-link-class="[bgTabsNavs, 'px-3']">
        <table-commissions-detail
            :info="info"
            :tab-controller="tabController"
            :role-c-digital="roleCDigital"
            :role-type="1"
        />
      </b-tab>
      <b-tab  lazy title="Commissions from department" :title-link-class="[bgTabsNavs, 'px-3']" >
        <table-commissions-detail
            :info="info"
            :tab-controller="tabController"
            :role-c-digital="roleCDigital"
            :role-type="2"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import TableCommissionsDetail from "@/views/commons/components/commissions/modals/modal-details/TableCommissionsDetail.vue";
import NewCommissionsService from "@/views/commons/components/commissions/services/new-commissions.service";
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    tabController: {
      type: Number,
      default: 0,
    },
    roleCDigital: {
      default: null,
    },
  },
  components: {
    TableCommissionsDetail,
  },
  data() {
    return {
      tabTitle: 'My Commissions',
      roleCustomerServiceDigital: 15,
    }
  },
  async created(){
    this.commissionService = new NewCommissionsService()
    const data = await this.commissionService.getUserRoleByModuleId({
      module_id: 22,
      user_id: this.info.user_id,
    })
    if (data.length > 0) {
      this.roleCustomerServiceDigital = data[0].role_id
      this.tabTitle = this.roleCustomerServiceDigital === 14 || this.roleCustomerServiceDigital === 2? 'Commissions from department ': 'My Commissions'
    }
  }
}
</script>

<style scoped>

</style>