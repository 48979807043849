import { amgApi } from '@/service/axios'

export default class NewCommissionsService {
  async searchCommissionsUserCrm(params) {
    try {
      const { data } = await amgApi.post(
        '/commissions-new/get-details-of-commissions-crm',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async searchCommissionsUserDepartment(params) {
    try {
      const { data } = await amgApi.post(
        '/commissions-new/get-details-of-commissions-departments',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async searchCommissionsDepartments(params) {
    try {
      const { data } = await amgApi.post(
        "/commissions-new/get-report-commissions-department",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchCommissions(params) {
    try {
      const { data } = await amgApi.post(
        '/commissions-new/get-report-commissions',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getUserRoleByModuleId(params) {
    try {
      const { data } = await amgApi.post(
        '/commissions-new/get-user-role-by-module-id',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getGeneralSupAssistCommissions(params) {
    try {
      const data = await amgApi.post(
        '/commissions-new/get-general-sup-assist-commissions',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getCeDigitalModulesByUser(params) {
    try {
      const data = await amgApi.post(
        '/commons/ce-digital/get-ce-digital-modules-by-user',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }
}
