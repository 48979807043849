<template>
  <div>
    <b-table
      :items="commissionsUser"
      :fields="fields"
      class="mt-2"
      responsive
      striped
      no-border-collapse
      sticky-header="50vh"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong>Loading...</strong>
        </div>
      </template>
      <template #empty>
        <div class="text-center text-primary my-2">
          <strong>No commissions found</strong>
        </div>
      </template>

      <template #head(account_status)>
        <div class="text-center">
          <span style="text-align: center">Status</span>
        </div>
      </template>
      <template v-if="isManagement" #cell(account)="data">
        <b-link>{{ data.value }}</b-link>
      </template>
      <template #cell(account_status)="data">
        <div class="text-center">
          <status-account :account="data.item" :text="true"></status-account>

          <!-- <b-icon
            v-if="data.value == 2"
            icon="triangle-fill"
            style="color: #ffc107"
          />
          <b-icon
            v-else
            icon="circle-fill"
            :style="`color:${
              data.value == 1 ? '#00CC00' : data.value == 3 ? '#0066FF' : 'red'
            }`"
          />
          <span
            v-if="data.item.account_status == 1"
            class="space-status-icon"
          >Active</span>
          <span
            v-if="data.item.account_status == 2"
            class="space-status-icon"
          >Hold </span>
          <span
            v-if="data.item.account_status == 3"
            class="space-status-icon"
          >Transition</span>
          <span
            v-if="data.item.account_status == 4"
            class="space-status-icon"
          >Canceled</span>
          <span
            v-if="data.item.account_status == 5"
            class="space-status-icon"
          >Loyal</span>
          <span
            v-if="data.item.account_status == 6"
            class="space-status-icon"
          >Closed</span> -->
        </div>
      </template>
      <template #cell(type)="data">
        <span>{{ data.item.type.toUpperCase() }}</span>
      </template>
      <template #cell(commission)="data">
        <span>$ {{ data.item.commission }}</span>
      </template>
      <template #cell(approve_date)="data">
        <span>{{ data.value | myGlobal }}</span>
      </template>
    </b-table>
    <b-row>
      <template v-if="isSupervisor || isAdvisor || isSeller || isAssistant">
        <b-col lg="6" :class="[textRightBig]">
          <div class="font-weight-bolder">SUM:</div>
        </b-col>
        <b-col lg="6" :class="[textLeftBig]">
          <div>$ {{ total_amount }}</div>
        </b-col>
      </template>

      <template v-if="isAdvisor || isSeller">
        <b-col lg="6" :class="[textRightBig]">
          <div class="font-weight-bolder">DISCOUNT:</div>
        </b-col>
        <b-col lg="6" :class="[textLeftBig]">
          <div>$ {{ discount }}</div>
        </b-col>
      </template>

      <template v-if="isSupervisor || isAssistant">
        <b-col lg="6" :class="[textRightBig]">
          <div v-if="info.year > 2022">
            <div class="font-weight-bolder">COMMISSIONS FROM DEPARTMENT</div>
          </div>
          <div v-else>
            <div v-if="currentDate >= '2021-11-24'" class="font-weight-bolder">
              20% OF COMMISSIONS
            </div>
            <div v-else class="font-weight-bolder">10% OF COMMISSIONS</div>
          </div>
        </b-col>
        <b-col lg="6" :class="[textLeftBig]">
          <div
            class="cursor-pointer"
            :class="[info.year > 2022 ? textLink : '']"
            @click="openDetailsSupAssistCommissions"
          >
            $ {{ total_department }}
          </div>
        </b-col>
      </template>

      <b-col lg="6" :class="[textRightBig]">
        <div class="font-weight-bolder">TOTAL:</div>
      </b-col>
      <b-col lg="6" :class="[textLeftBig]">
        <div>$ {{ total }}</div>
      </b-col>
    </b-row>
    <details-sup-assist-commissions
      v-if="detailsSupAssistCommissionsOn"
      :info="info"
      @close="closeDetailsSupAssistCommissions"
    />
  </div>
</template>

<script>
import moment from "moment";
import commissionsService from "@/views/commons/components/commissions/services/commissions.service";
import CommissionsClassService from "@/views/commons/components/commissions/services/commissions-class-service";
import NewCommissionsService from "@/views/commons/components/commissions/services/new-commissions.service";
import DetailsSupAssistCommissions from "@/views/commons/components/commissions/modals/modal-details/DetailsSupAssistCommissions";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import { mapGetters } from "vuex";

export default {
  name: "DetailsDepartments",
  components: {
    DetailsSupAssistCommissions,
    StatusAccount,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    fromSales: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commissionsUser: [],
      fields: [
        {
          key: "lead",
          label: "Client",
        },
        {
          key: "account",
          label: "Account",
        },
        {
          key: "account_status",
          label: "Status",
        },
        {
          key: "value",
          label: "Program",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "commission",
          label: "Amount",
        },
        {
          key: "approve_date",
          label: "Approved Date",
        },
      ],
      isBusy: true,
      total_amount: "",
      discount: "",
      total: "",
      total_department: "",
      currentDate: moment().format("YYYY-MM-DD"),
      commissionObject: {},
      detailsSupAssistCommissionsOn: false,
    };
  },
  created() {
    if (Number(this.info.year) > 2021) {
      this.commissionObject = new NewCommissionsService();
    } else {
      this.commissionObject = new CommissionsClassService();
    }
    this.searchCommissions();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      bigWindow: "app/bigWindow",
    }),
    textRightBig() {
      return this.bigWindow ? "text-right" : "text-center";
    },
    textLeftBig() {
      return this.bigWindow ? "text-left" : "text-center mb-1";
    },
    isSupervisor() {
      return this.info.role_id == 2;
    },
    isAssistant() {
      return this.info.role_id == 14;
    },
    isAdvisor() {
      return this.info.role_id == 3;
    },
    isSeller() {
      return this.info.role_id == 5;
    },
    isManagement() {
      return this.info.moduleSession == 16;
    },
  },
  methods: {
    closeDetailsSupAssistCommissions() {
      this.detailsSupAssistCommissionsOn = false;
    },
    openDetailsSupAssistCommissions() {
      if (
        this.info.year == 2023 &&
        [1, 2, 3, 4].includes(Number(this.info.month))
      ) {
        return;
      }
      if (this.info.year > 2022) {
        this.detailsSupAssistCommissionsOn = true;
      }
    },
    async searchCommissions() {
      const response =
        await this.commissionObject.searchCommissionsUserDepartment(this.info);
      if (response.length > 0) {
        this.filterCommissions(response);
        this.total_amount = response[0].total_amount;
        this.discount = response[0].discount;
        this.total = response[0].total;
        this.total_department = response[0].commission_department;
      }
      this.isBusy = false;
    },
    filterCommissions(commissions) {
      // Get Commissions, Just for User
      this.commissionsUser = commissions.filter(
        (commission) => commission.user_id == this.info.user
      );
    },
  },
  watch: {
    async info() {
      await this.searchCommissions();
    },
  },
};
</script>

<style scoped>
.space-status-icon {
  padding-left: 0.15rem;
}
</style>
