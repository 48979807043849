<template>
  <div>
    <template v-if="isCrm">
      <div>
        <a
          @click="findCommissions('crm', info.month_pay)"
          class="name-class color-gray-m cursor-pointer"
          v-b-tooltip.hover
          :title="info.monthUser"
          v-if="info.month_pay != '0.00'"
          >$ {{ info.month_pay | currency }}
        </a>
      </div>

      <template v-if="info.month_pay != '0.00' && !isSupervisor">
        <b-form-input
          min="1"
          max="100"
          type="number"
          v-model="percentage"
          @keyup.native="maxPercentage()"
          :disabled="!editPercentage"
          class="d-inline-flex text-center w-input-percentage p-0 mt-2 font-fam"
        ></b-form-input>
        <buttons-edit
          v-if="moduleId == 16"
          :editMode="editPercentage"
          @edit="editPercentageUser()"
          @cancel="cancelPercentageUser()"
          @update="updatePercentageUser()"
        ></buttons-edit>
      </template>
    </template>

    <template v-if="admCrm || isSupervisorCrm">
      <div :class="{ 'mt-2': isCrm }" v-if="info.month_pay != '0.00'">
        <a
          @click="findCommissions('crm', info.month_pay)"
          class="name-class text-color-m cursor-pointer"
          v-b-tooltip.hover
          :title="info.monthUser"
        >
          $

          <span v-if="tab == 'crm'">{{ info.to_pay | currencyZero }}</span>
          <span v-else>{{ info.month_pay | currencyZero }}</span>
        </a>

        <template v-if="moduleId == 16">
          <b-button
            v-if="info.ps == null"
            variant="outline-warning"
            class="btn-icon button-size button-margin-l"
            v-b-tooltip.hover.bottom="'NOT PAID'"
            @click="payCommissions()"
          >
            <feather-icon icon="ClockIcon"></feather-icon>
          </b-button>

          <b-button
            v-if="info.ps == 1"
            variant="outline-success"
            class="btn-icon button-size button-margin-l"
            v-b-tooltip.hover.bottom="'PAID'"
            @click="payCommissions()"
          >
            <feather-icon icon="ClockIcon"></feather-icon>
          </b-button>

          <b-button
            v-if="info.ps == 2"
            variant="outline-danger"
            class="btn-icon button-size button-margin-l"
            v-b-tooltip.hover.bottom="'ANULL'"
            @click="payCommissions()"
          >
            <feather-icon icon="ClockIcon"></feather-icon>
          </b-button>
        </template>
      </div>
    </template>

    <template v-if="!admCrm && total">
      <div>
        <a
          v-if="payDepartment"
          @click="findCommissions('program')"
          class="name-class text-color-m cursor-pointer"
          v-b-tooltip.hover
          :title="`${info.monthUser}-ADD/CHANGE`"
          >$ {{ payDepartment | currency }}
        </a>
        <span v-else class="name-class text-color-m">$ 0.00</span>
      </div>

      <div class="margin-t-pay">
        <a
          v-if="payDepartmentCrm"
          @click="findCommissions('crm', payDepartmentCrm)"
          class="name-class text-color-m cursor-pointer"
          v-b-tooltip.hover
          :title="`${info.monthUser}-CRM`"
          >$ {{ payDepartmentCrm | currency }} </a
        >
        <span v-else class="name-class text-color-m">$ 0.00</span>
      </div>

      <!--business-->
      <div
        class="business_class name-class"
        v-if="isBusiness || getTabModuleId == 3"
      >
        <a
          v-if="charge"
          href="javascript:;"
          class="text-color-m"
          @click="findCommissions('business', charge)"
        >
          $ {{ charge | currency }}
        </a>
        <span v-else class="name-class text-color-m"> $ 0.00 </span>
      </div>
      <!--specialists-->
      <div
        class="business_class name-class"
        v-if="isSpecialists || getTabModuleId == 11"
      >
        <a
          v-if="spe_charge"
          href="javascript:;"
          class="text-color-m"
          @click="findCommissions('specialists', spe_charge)"
        >
          $ {{ spe_charge | currency }}
        </a>
        <span v-else class="name-class text-color-m"> $ 0.00 </span>
      </div>

      <!--ce digital-->
      <div
        class="business_class name-class"
        v-if="isCeDigital || getTabModuleId == 6"
      >
        <a
          v-if="ce_payment"
          href="javascript:;"
          class="text-color-m"
          @click="findCommissions('ce-digital', ce_payment)"
        >
          $ {{ ce_payment | currency }}
        </a>
        <span v-else class="name-class text-color-m"> $ 0.00 </span>
      </div>

      <div class="name-class margin-t-pay">
        <span v-b-tooltip.hover :title="`${info.monthUser}-TOTAL`">
          $ {{ total | currencyZero }}
        </span>

        <template v-if="moduleId == 16">
          <b-button
            v-if="psDepartment == null"
            variant="outline-warning"
            class="btn-icon button-size button-margin-l"
            v-b-tooltip.hover.bottom="'NOT PAID'"
            @click="payCommissions()"
          >
            <feather-icon icon="ClockIcon"></feather-icon>
          </b-button>

          <b-button
            v-if="psDepartment == 1"
            variant="outline-success"
            class="btn-icon button-size button-margin-l"
            v-b-tooltip.hover.bottom="'PAID'"
            @click="payCommissions()"
          >
            <feather-icon icon="ClockIcon"></feather-icon>
          </b-button>

          <b-button
            v-if="psDepartment == 2"
            variant="outline-danger"
            class="btn-icon button-size button-margin-l"
            v-b-tooltip.hover.bottom="'ANULL'"
            @click="payCommissions()"
          >
            <feather-icon icon="ClockIcon"></feather-icon>
          </b-button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ButtonsEdit from "@/views/commons/utilities/ButtonsEdit";
import moment from "moment";

export default {
  name: "CommissionsMonthly",
  components: { ButtonsEdit },
  emits: ["edit", "cancel", "update", "max"],
  props: {
    editPercentage: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: {
        ps: 1,
        to_pay: "0.00",
        percentage_pay: "0.00",
        month_pay: "0.00",
        monthUser: "",
        rol: null,
        monthYear: null,
      },
    },
    halfYear: {
      type: Boolean,
    },
    tab: {
      type: String,
      default: "crm",
    },
    isManagement: {
      type: Boolean,
      default: false,
    },
    commissionsDepartments: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      percentage: null,
      returnPercentage: null,
      payDepartment: 0,
      payDepartmentCrm: 0,
      psDepartment: null,

      payDepartmentBusiness: 0,
      charge: 0,
      spe_charge: 0,

      payDepartmentCeDigital: 0,
      ce_payment: 0,

      nameMonth: "",
    };
  },
  created() {
    this.getCommissionsDepartments();
  },
  mounted() {
    this.percentage = this.info.percentage_pay;
  },
  computed: {
    ...mapGetters({
      getTabModuleId: "commissions-store/tabModuleId",
    }),
    isSupervisor() {
      return this.info.rol == 2;
    },
    admCrm() {
      return this.tab == "adm" || this.tab == "crm";
    },
    isCrm() {
      return this.tab == "crm";
    },
    isSupervisorCrm() {
      return this.tab === "supervisorCrm";
    },
    total() {
      //return this.payDepartmentCrm + this.payDepartment + this.charge;

      if (this.moduleId == 3) {
        //from bussiness
        return this.charge + this.payDepartmentCrm + this.payDepartment;
      } else if (this.moduleId == 29) {
        //from specialists
        return this.spe_charge + this.payDepartmentCrm + this.payDepartment;
      } else if (
        this.moduleId == 6 ||
        this.moduleId == 26 ||
        this.moduleId === 22 ||
        this.moduleId === 23 ||
          this.moduleId === 20
      ) {
        //from ce_digital
        return this.ce_payment + this.payDepartmentCrm + this.payDepartment;
      } else if (this.moduleId == 16 && this.getTabModuleId == 6) {
        //from management and tab ce_digital
        return this.ce_payment + this.payDepartmentCrm + this.payDepartment;
      } else if (this.moduleId == 16 && this.getTabModuleId == 3) {
        //from management and tab bussiness
        return this.charge + this.payDepartmentCrm + this.payDepartment;
      } else if (this.moduleId == 16 && this.getTabModuleId == 11) {
        //from management and tab specialists
        return this.spe_charge + this.payDepartmentCrm + this.payDepartment;
      } else {
        //from global
        return this.payDepartmentCrm + this.payDepartment;
      }
    },
    buttonsDisabled() {
      //Disabled for months y the past
      let today = moment();
      let month = moment(this.info.monthYear);
      let difference = today.diff(month, "months");
      return difference > 1;
    },
    isBusiness() {
      return this.moduleId == 3;
    },
    isSpecialists() {
      return [11,29].includes(this.moduleId);
    },
    isCeDigital() {
      return this.moduleId == 6 || this.moduleId == 23 || this.moduleId == 26 || this.moduleId == 20;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    //Percentage to 100
    maxPercentage() {
      if (Number(this.percentage) > 100) {
        this.percentage = "100.00";
      }
    },
    //Disable buttons

    //COMMISSIONS DEPARTMENT
    getCommissionsDepartments() {
      this.payDepartment = 0;

      if (!this.admCrm) {
        this.commissionsDepartments.forEach((item, index) => {
          //Status Pay
          if (item["ps_" + this.month]) {
            this.psDepartment = item["ps_" + this.month];
          }

          //Business
          this.charge = item["charge_" + this.month];
          this.nameMonth = this.month;

          //Specialists
          this.spe_charge = item["specialist_" + this.month];
          this.nameMonth = this.month;

          //CeDigital
          this.ce_payment = item["ce_digital_" + this.month];
          this.nameMonth = this.month;

          //Pay Crm o Department
          if (item.module_id == 2) {
            this.payDepartmentCrm = item[this.month];
          } else {
            if (this.moduleId == 16 && this.getTabModuleId == 3) {
              //from management and tab bussiness
              if (item[this.month] > 0) {
                this.payDepartment = item[this.month];
              }
            } else if (this.moduleId == 3) {
              //from bussiness
              if (item[this.month] > 0) {
                this.payDepartment = item[this.month];
              }
            } else {
              //global
              this.payDepartment = item[this.month];
            }
          }
        });
      }
    },

    //EMITS
    findCommissions(type, amountTotal = null) {
      this.A_TOTAL_COMMISSION_CE_DIGITAL(this.ce_payment)
      this.$emit("find", { type, amountTotal });
    },
    payCommissions() {
      let eventPay = {
        ps: this.psDepartment,
        total: this.total,
      };
      this.$emit("pay", eventPay);
    },
    editPercentageUser() {
      this.returnPercentage = JSON.parse(JSON.stringify(this.percentage));
      this.$emit("edit");
    },
    cancelPercentageUser() {
      this.percentage = this.returnPercentage;
      this.$emit("cancel");
    },
    updatePercentageUser() {
      this.$emit("update", this.percentage);
    },
    ...mapActions({
      A_TOTAL_COMMISSION_CE_DIGITAL: "commissions-store/A_TOTAL_COMMISSION_CE_DIGITAL",
    }),
  },
  watch: {
    halfYear() {
      this.percentage = this.info.percentage_pay;
      this.getCommissionsDepartments();
    },
    info(newInfo, oldInfo) {
      if (newInfo) {
        this.getCommissionsDepartments();
      }
    },
  },
};
</script>

<style scoped>
.button-size {
  height: 23px;
  padding: 3px;
}
.w-input-percentage {
  width: 4.1rem;
}
.border-top-dash {
  border-top: 1px solid #ff6045;
}
.border-left-dash {
  border-left: 1px dashed #ff6045;
}

.bg-button-orange {
  background: #ff7f00;
}
.bg-button-green {
  background: #1cc700;
}

.color-gray-m {
  color: #9f9da8 !important;
}

.text-color-m {
  color: #666371 !important;
}

.text-color-m:hover {
  color: #ff7f00 !important;
}
.font-fam {
  font-family: "Rubik";
  font-style: normal;
}
.name-class {
  font-size: 15px;
  line-height: 11px;
  font-weight: 600;
}

.margin-t-pay {
  margin-top: 29px;
}
</style>

<style lang="scss">
.business_class {
  margin: 35px 0 32px 0;
}
</style>
