<template>
  <div>
    <b-table
      :items="commissionsUser"
      :fields="fields"
      class="mt-2"
      responsive
      striped
      no-border-collapse
      sticky-header="50vh"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #empty>
        <div class="text-center text-primary my-2">
          <strong>No commissions found</strong>
        </div>
      </template>

      <template #head(account_status)>
        <div class="text-center">
          <span style="text-align: center">Status</span>
        </div>
      </template>
      <template #cell(account)="data" v-if="isManagement">
        <b-link>{{ data.value }}</b-link>
      </template>
      <template #cell(account_status)="data">
        <div>
          <status-account :account="data.item" :text="true"></status-account>
        </div>
        <!-- <div class="text-center">
          <b-icon
            v-if="data.value == 2"
            icon="triangle-fill"
            style="color: #ffc107"
          ></b-icon>
          <b-icon
            v-else
            icon="circle-fill"
            :style="`color:${
              data.value == 1 ? '#00CC00' : data.value == 3 ? '#0066FF' : 'red'
            }`"
          ></b-icon>
          <span v-if="data.item.account_status == 1" class="space-status-icon"
            >Active</span
          >
          <span v-if="data.item.account_status == 2" class="space-status-icon"
            >Hold
          </span>
          <span v-if="data.item.account_status == 3" class="space-status-icon"
            >Transition</span
          >
          <span v-if="data.item.account_status == 4" class="space-status-icon"
            >Canceled</span
          >
          <span v-if="data.item.account_status == 5" class="space-status-icon"
            >Loyal</span
          >
          <span v-if="data.item.account_status == 6" class="space-status-icon"
            >Closed</span
          >
        </div> -->
      </template>
      <!-- <template #cell(type)="data">
        <span>{{(data.item.type).toUpperCase()}}</span>
      </template> -->
      <template #cell(commission)="data">
        <span>$ {{ data.item.commission }}</span>
      </template>
      <!-- <template #cell(approve_date)="data">
        <span>{{data.value | myGlobal}}</span>
      </template> -->
      <template #cell(created_at)="data">
        <span>{{ data.value | myGlobal }}</span>
      </template>
    </b-table>
    <b-row>
      <template v-if="isSupervisor || isAdvisor || isSeller">
        <b-col lg="6" :class="[textRightBig]">
          <div class="font-weight-bolder">SUM:</div>
        </b-col>
        <b-col lg="6" :class="[textLeftBig]">
          <div>$ {{ total_amount }}</div>
        </b-col>
      </template>

      <template v-if="isAdvisor || isSeller">
        <b-col lg="6" :class="[textRightBig]">
          <div class="font-weight-bolder">DISCOUNT:</div>
        </b-col>
        <b-col lg="6" :class="[textLeftBig]">
          <div>$ {{ discount }}</div>
        </b-col>
      </template>

      <template v-if="isSupervisor">
        <b-col lg="6" :class="[textRightBig]">
          <div v-if="currentDate >= '2021-11-24'" class="font-weight-bolder">
            20% OF COMMISSIONS
          </div>
          <div v-else class="font-weight-bolder">10% OF COMMISSIONS</div>
          <!-- <div class="font-weight-bolder"></div> -->
        </b-col>
        <b-col lg="6" :class="[textLeftBig]">
          <div>$ {{ commissions_additional }}</div>
        </b-col>
      </template>

      <b-col lg="6" :class="[textRightBig]">
        <div class="font-weight-bolder">TOTAL:</div>
      </b-col>
      <b-col lg="6" :class="[textLeftBig]">
        <div>$ {{ total }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import commissionsService from "@/views/commons/components/commissions/services/commissions.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import { mapGetters } from "vuex";
export default {
  name: "DetailsDepartments",
  components: {
    StatusAccount,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      commissionsUser: [],
      fields: [],
      isBusy: true,
      total_amount: "",
      discount: "",
      total: "",
      total_department: "",
      commissions_additional: 0,
      currentDate: moment().format("YYYY-MM-DD"),
    };
  },
  created() {
    this.searchCommissions();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      bigWindow: "app/bigWindow",
    }),
    textRightBig() {
      return this.bigWindow ? "text-right" : "text-center";
    },
    textLeftBig() {
      return this.bigWindow ? "text-left" : "text-center mb-1";
    },
    isSupervisor() {
      return this.info.role_id == 2;
    },
    isAdvisor() {
      return this.info.role_id == 3;
    },
    isSeller() {
      return this.info.role_id == 5;
    },
    isManagement() {
      return this.info.moduleSession == 16;
    },
  },
  methods: {
    async searchCommissions() {
      this.getFields();
      let response = await commissionsService.getDetailsOfCharge(this.info);
      this.filterCommissions(response.data);
      this.total_amount = response.total;
      this.discount = response.discount;
      this.commissions_additional = response.commissions_additional;
      let total =
        parseFloat(response.total) -
        parseFloat(response.discount) +
        parseFloat(response.commissions_additional);
      this.total = total.toFixed(2);
      this.isBusy = false;
    },
    getFields() {
      this.fields = [
        {
          key: "lead",
          label: "Client",
        },
        {
          key: "account",
          label: "Account",
        },
        {
          key: "account_status",
          label: "Status",
        },
        {
          key: "value",
          label: "Program",
        },
        // {
        //   key: "type",
        //   label: "Type"
        // },
        {
          key: "charge",
          label: "type charge",
        },
        {
          key: "commission",
          label: "Amount",
        },
        // {
        //   key: "approve_date",
        //   label: "Approved Date"
        // },
        {
          key: "created_at",
          label: "Created Date",
        },
      ];
    },
    filterCommissions(commissions) {
      //Get Commissions, Just for User
      // this.commissionsUser = commissions.filter(commission => {
      //   return commission.user_id == this.info.user;
      // });
      this.commissionsUser = commissions.map((item) => ({
        ...item,
        commission: item.amount,
        lead: item.client,
        value: item.program,
        status: item.status,
      }));
    },
  },
  watch: {},
};
</script>

<style scoped>
.space-status-icon {
  padding-left: 0.15rem;
}
</style>
