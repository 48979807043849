<template>
  <div>
    <b-tabs
      lazy
      pills
      nav-class="mb-0"
      active-nav-item-class="bg-primary box-shadow-info"
    >
      <b-tab
        lazy
        title="Customer Service"
        :title-link-class="[bgTabsNavs, 'px-3']"
        @click="tabController = 22"
      >
        <details-customer-service-digital
          :info="info"
          :tab-controller="tabController"
          :role-c-digital="roleCDigital"
        />
      </b-tab>
      <b-tab
        lazy
        title="Financial"
        :title-link-class="[bgTabsNavs, 'px-3']"
        @click="tabController = 23"
      >
        <details-financial
          class="mt-1"
          :info="info"
          :tab-controller="tabController"
        />
      </b-tab>
      <b-tab
        lazy
        title="Sales"
        :title-link-class="[bgTabsNavs, 'px-3']"
        @click="tabController = 26"
      >
        <b-tabs
          lazy
          pills
          nav-class="mb-0 mt-1"
          active-nav-item-class="bg-info box-shadow-info"
        >
          <b-tab
            lazy
            title="For Sales"
            :title-link-class="[bgTabsNavs, 'px-3']"
            @click="tabController = 26"
            
          >
            <details-departments :info="infoB" :from-sales="true" />
          </b-tab>
          <b-tab
            lazy
            title="For Charges"
            :title-link-class="[bgTabsNavs, 'px-3']"
            @click="tabController = 26"
          >
            <details-sales
              class="mt-1"
              :info="info"
              :tab-controller="tabController"
            />
          </b-tab>
        </b-tabs>
      </b-tab>
      <b-tab
        lazy
        title="Connection"
        :title-link-class="[bgTabsNavs, 'px-3']"
        @click="tabController = 20"
      >
        <details-connection
          class="mt-1"
          :info="info"
          :tab-controller="tabController"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import DetailsDepartments from "@/views/commons/components/commissions/modals/modal-details/DetailsDepartments.vue"
import DetailsFinancial from "@/views/commons/components/commissions/modals/modal-details/DetailsFinancial.vue"
import NewCommissionsService from "@/views/commons/components/commissions/services/new-commissions.service"
import DetailsCustomerServiceDigital from "@/views/commons/components/commissions/modals/modal-details/DetailsCustomerServiceDigital.vue"
import DetailsConnection from "@/views/commons/components/commissions/modals/modal-details/DetailsConnection.vue"
import DetailsSales from "@/views/commons/components/commissions/modals/modal-details/DetailsSales.vue"
import { mapGetters } from "vuex"

export default {
  components: {
    DetailsDepartments,
    DetailsFinancial,
    DetailsCustomerServiceDigital,
    DetailsConnection,
    DetailsSales
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      infoB: {
        user: this.info.user_id,
        year: this.info.year,
        month: this.info.month,
        role: this.roleSales ? this.roleSales : 3,
        role_id: this.roleSales ? this.roleSales : 3,
        module: 26,
      },
      tabController: 22,
      roleSales: null,
      roleCDigital: null,
      commissionService: {},
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    this.commissionService = new NewCommissionsService()
    const data = await this.commissionService.getUserRoleByModuleId({
      module_id: 26,
      user_id: this.info.user_id,
    })
    if (data.length > 0) {
      this.roleSales = data[0].role_id
    }
    const data2 = await this.commissionService.getUserRoleByModuleId({
      module_id: 22,
      user_id: this.info.user_id,
    })
    if (data2.length > 0) {
      this.roleCDigital = data2[0].role_id
    }
    this.infoB = {
      user: this.info.user_id,
      year: this.info.year,
      month: this.info.month,
      role: this.roleSales ? this.roleSales : 3,
      role_id: this.roleSales ? this.roleSales : 3,
      module: 26,
    }
  },
}
</script>

<style scoped>
.space-status-icon {
  padding-left: 0.15rem;
}
</style>
