<template>
  <div>
    <b-table
      :items="searchCommissions"
      :fields="visibleFields"
      class="mt-2"
      responsive
      striped
      no-border-collapse
      sticky-header="50vh"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong>Loading...</strong>
        </div>
      </template>
      <template #empty>
        <div class="text-center text-primary my-2">
          <strong>No commissions found</strong>
        </div>
      </template>

      <template #head(account_status)>
        <div class="text-center">
          <span style="text-align: center">Status</span>
        </div>
      </template>
      <template #cell(account)="data">
        <div v-if="data.item.account != null">
          <b-link v-if="isManagement">{{ data.value }}</b-link>
          <span v-else> {{ data.value }} </span>
        </div>
        <div v-else>
          <span> - </span>
        </div>
      </template>
      <template #cell(account_status)="data">
        <div
          class="text-center d-flex justify-content-center align-items-center"
          v-if="data.item.account != null"
        >
          <IconStatusAccount
            :status="data.item.account_status"
            fromBc
            class="d-inline mr-1"
          />
          <span v-if="data.item.account_status == 1" class="space-status-icon"
            >Active</span
          >
          <span v-if="data.item.account_status == 2" class="space-status-icon"
            >Hold
          </span>
          <span v-if="data.item.account_status == 3" class="space-status-icon"
            >Transition</span
          >
          <span v-if="data.item.account_status == 4" class="space-status-icon"
            >Canceled</span
          >
          <span v-if="data.item.account_status == 5" class="space-status-icon"
            >Loyal</span
          >
          <span v-if="data.item.account_status == 6" class="space-status-icon"
            >Closed</span
          >
        </div>
        <div v-else class="text-center">
          <span>-</span>
        </div>
      </template>
      <template #cell(created_at)="data">
        <span>{{ data.value | myGlobal }}</span>
      </template>
    </b-table>
    <b-row>
      <template>
        <b-col lg="6" :class="[textRightBig]">
          <div class="font-weight-bolder">SUM:</div>
        </b-col>
        <b-col lg="6" :class="[textLeftBig]">
          <div>$ {{ total_amount }}</div>
        </b-col>
      </template>
      <template>
        <b-col lg="6" :class="[textRightBig]">
          <div class="font-weight-bolder">DISCOUNT:</div>
        </b-col>
        <b-col lg="6" :class="[textLeftBig]">
          <div>$ {{ discount ? Number(discount).toFixed(2) : "-" }}</div>
        </b-col>
      </template>

      <b-col lg="6" :class="[textRightBig]">
        <div class="font-weight-bolder">TOTAL:</div>
      </b-col>
      <b-col lg="6" :class="[textLeftBig]">
        <div>$ {{ total }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import commissionsService from "@/views/commons/components/commissions/services/commissions.service";
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";

export default {
  components: {
    IconStatusAccount,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isBusy: false,
      total_amount: "",
      discount: "",
      total: "",
      total_department: "",
      currentDate: moment().format("YYYY-MM-DD"),
      fields: [
        {
          key: "client_name",
          label: "Client",
          visible: true,
          thClass: "text-left",
          tdClass: "text-left pl-2",
        },
        {
          key: "account",
          label: "Account",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center p-0",
        },
        {
          key: "account_status",
          label: "Status",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center p-0",
        },
        {
          key: "module_name",
          label: "module",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center p-0",
        },
        {
          key: "description",
          label: "type Commission",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center p-0",
        },
        {
          key: "sum_comm",
          label: "Amount",
          formatter: (val) => `$ ${val}`,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center p-0",
        },
        {
          key: "created_at",
          label: "Created Date",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center p-0",
        },
      ],
    };
  },
  created() {},
  mounted() {
    const indexAmount = this.fields.indexOf(
      this.fields.find((field) => field.key === "sum_comm")
    );
    this.fields[indexAmount].visible = [1, 2, 14].includes(
      this.currentUser.modul_id
    );
  },
  computed: {
    ...mapGetters({
      bigWindow: "app/bigWindow",
      currentUser: "auth/currentUser",
    }),
    textRightBig() {
      return this.bigWindow ? "text-right" : "text-center";
    },
    textLeftBig() {
      return this.bigWindow ? "text-left" : "text-center mb-1";
    },
    isManagement() {
      return this.info.moduleSession == 16;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    async searchCommissions() {
      // console.log(this.info);
      const response = await commissionsService.getDetailsOfSpecialists({
        ...this.info,
      });
      this.total_amount = response.total;
      this.discount = response.discount;
      const total = parseFloat(response.total) - parseFloat(response.discount);
      this.total = total.toFixed(2);
      return response.data;
    },
  },
  watch: {},
};
</script>

<style scoped></style>
