<template>
  <b-tabs
    lazy
    pills
    nav-class="mb-0"
    active-nav-item-class="bg-primary box-shadow-info"
  >
    <b-tab title="User Commissions" :title-link-class="[bgTabsNavs, 'px-3']">
      <table-commissions-detail
        :role-type="1"
        :info="info"
        :tab-controller="tabController"
      />
    </b-tab>
    <b-tab
      lazy
      title="Add/Change Service"
      :title-link-class="[bgTabsNavs, 'px-3']"
    >
      <details-departments :info="infoB" :from-sales="false" />
    </b-tab>
    <b-tab
      lazy
      title="Commissions from department"
      :title-link-class="[bgTabsNavs, 'px-3']"
    >
      <table-commissions-detail
        :info="info"
        :tab-controller="tabController"
        :role-type="3"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import TableCommissionsDetail from "@/views/commons/components/commissions/modals/modal-details/TableCommissionsDetail.vue"
import DetailsDepartments from "@/views/commons/components/commissions/modals/modal-details/DetailsDepartments"
import NewCommissionsService from "@/views/commons/components/commissions/services/new-commissions.service"
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    tabController: {
      type: Number,
      default: 0,
    },
  },
  name: "DetailsConnection",
  components: {
    TableCommissionsDetail,
    DetailsDepartments,
  },
  data() {
    return {
      infoB: {},
      roleSales: null,
      commissionService: {},
    }
  },
  async created() {
    this.commissionService = new NewCommissionsService()
    const data = await this.commissionService.getUserRoleByModuleId({
      module_id: 20,
      user_id: this.info.user_id,
    })
    if (data.length > 0) {
      this.roleSales = data[0].role_id
    }
    this.infoB = {
      user: this.info.user_id,
      year: this.info.year,
      month: this.info.month,
      role: this.roleSales ? this.roleSales : 3,
      role_id: this.roleSales ? this.roleSales : 3,
      module: 20,
    }
  },
}
</script>

<style scoped></style>
