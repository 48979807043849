<template>
  <b-modal
    v-model="onControl"
    title="Commissions from department details"
    title-tag="h3"
    modal-class="modal-primary"
    size="xmd"
    hide-footer
    @hidden="close"
  >
    <filter-slot
      :filter="[]"
      :no-visible-principal-filter="true"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['detailsTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="detailsTable"
          :items="searchCommissions"
          :fields="fields"
          class="mt-2"
          responsive
          striped
          no-border-collapse
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          sticky-header="50vh"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-center text-primary my-2">
              <strong>No commissions found</strong>
            </div>
          </template>
          <template
            v-if="$route.matched[0].meta.module === 16"
            #cell(account)="data"
          >
            <b-link>{{ data.value }}</b-link>
          </template>
          <template #cell(lead)="data">
            {{data.item.lead}}
            <br>
            {{data.item.account}}
          </template>
          <template #cell(type)="data">
            <span>{{ (data.item.type).toUpperCase() }}</span>
          </template>
          <template #cell(amount_for_sup)="data">
            <span>$ {{ (data.item.amount_for_sup) }}</span>
          </template>
          <template #cell(approve_date)="data">
            <span>{{ data.value | myGlobal }}</span>
          </template>
          <template #custom-foot>
            <b-tr class="bg-dark ">
              <b-th>
                <span
                  class="font-medium-1"
                >Total</span>
              </b-th>
              <b-th />
              <b-th />
              <b-th />
              <b-th
                class="footer-dark"
              >
                <div class="footer-span w-100">
                  <span class=" font-small-3  ">$ {{
                    totalCommissions | currency
                  }} </span>
                </div>
              </b-th>
              <b-th />

            </b-tr>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import NewCommissionsService from '@/views/commons/components/commissions/services/new-commissions.service'

export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      onControl: false,
      fields: [
        {
          key: 'lead',
          label: 'Client',
        },
        {
          key: 'seller',
          label: 'Seller',
        },
        {
          key: 'value',
          label: 'Program',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'amount_for_sup',
          label: 'Amount',
        },
        {
          key: 'approve_date',
          label: 'Approved Date',
        },
      ],
      commissionsUser: [],
      isBusy: true,
      commissionObject: {},
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Name of category...',
        model: '',
      },
      totalCommissions: 0,
    }
  },
  async created() {
    this.commissionObject = new NewCommissionsService()
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async searchCommissions(ctx) {
      try {
        const data = await this.commissionObject.getGeneralSupAssistCommissions({
          userId: this.info.user,
          moduleId: this.info.module,
          perPage: ctx.perPage,
          nPage: ctx.currentPage,
          year: this.info.year,
          month: this.info.month
        })
        this.commissionsUser = data.data.data
        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.totalRows = data.data.total
        this.toPage = data.data.to
        if (this.commissionsUser.length > 0) {
          this.totalCommissions = this.commissionsUser[0].total_commission
        }
        return this.commissionsUser || []
      } catch (e) {
        console.log('Errors on DetailsSupAssistCommissions: searchCommissions', e)
      }
    },
  },
}
</script>

<style scoped>

</style>
